<template>
  <div v-cloak>
    <LoadingComponent v-if="!apiLoaded" :text="loadingText"></LoadingComponent>
    <transition appear appear-active-class="fade-enter-active" name="fade">
      <div v-if="apiLoaded || errorText">
        <div v-if="apiLoaded" class="wrap">
          <div class="header block">
            <div class="header-passport">
              <h1>
                Паспорт региона
              </h1>
              <!--            <span v-if="fromCZNMap && !($user.role==='ROLE_ADMIN')" class="subheader">-->
              <!--              {{userRegion.name}}</span>-->
              <span v-if="this.fromCZNMap
                        || ($user.role==='ROLE_CZN_MANAGER'
                        || $user.role==='ROLE_CZN_TERRITORIAL'
                        || $user.role==='ROLE_REGIONAL_AGENCY')" class="subheader">
              {{ userRegion.name }}</span>
              <v-autocomplete
                  v-else
                  style="width: 500px; margin-top: 10px"
                  dense
                  label="Регион"
                  :items="regionList"
                  item-text="name"
                  return-object
                  v-model="userRegion"
                  @input="initialize(); departments = userRegion.departments;"
              ></v-autocomplete>
            </div>

            <div class="passport-buttons">
              <v-dialog
                  v-model="dialog"
                  max-width="800px"
                  persistent>
                <template v-slot:activator="{ on }">
                  <div v-on="on">
                    <v-btn outlined>
                      документы
                    </v-btn>
                  </div>
                </template>
                <PassportInfoDocsDialog
                    :region="userRegion"
                    :departments="departments"
                    section="Паспорт региона"
                    @close="dialog=false; dialogKey++"
                    :key="dialogKey" @edit="dialog=false; dialogUpload=true;"/>
              </v-dialog>
              <v-btn outlined @click="$router.push('/passport/archive')"
                     v-if="$user.role==='ROLE_ADMIN' || $user.role==='ROLE_VNII'">
                архив<br>документов
              </v-btn>
              <v-dialog
                  v-if="$user.role==='ROLE_ADMIN' || $user.role==='ROLE_VNII'"
                  v-model="dialogUpload"
                  max-width="800px"
                  persistent>
                <template v-slot:activator="{ on }">
                  <div v-on="on">
                    <v-btn outlined>
                      загрузить<br>документ
                    </v-btn>
                  </div>
                </template>
                <PassportInfoDocsUploadDialog
                    :region="userRegion"
                    :departments="departments"
                    section="Паспорт региона"
                    :categories="config.documentCategories"
                    @close="dialogUpload=false; dialogKey++"
                    :key="dialogKey"/>
              </v-dialog>

              <div class="download-menu">
                <v-menu top>
                  <template v-slot:activator="{ on }">
                    <div class="download-menu-icon" v-on="on">
                      <svg
                          fill="none"
                          height="24"
                          viewBox="0 0 24 24"
                          width="24"
                          xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                            d="M8 17V15H16V17H8ZM16 10L12 14L8 10H10.5V7H13.5V10H16ZM5 3H19C20.11 3 21 3.9 21 5V19C21 20.11 20.11 21 19 21H5C3.9 21 3 20.11 3 19V5C3 3.9 3.9 3 5 3ZM5 5V19H19V5H5Z"
                            fill="#0033A0"
                        />
                      </svg>
                    </div>
                  </template>

                  <v-list>
                    <v-list-item @click.prevent="exportXls()">
                      <v-list-item-title>Скачать XLSX</v-list-item-title>
                    </v-list-item>
                    <v-list-item @click.prevent="exportPdf()">
                      <v-list-item-title>Скачать PDF</v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-menu>
              </div>
            </div>
          </div>
          <!--          mapCenter - {{mapCenter}}<br>-->
          <!--          markers - {{markers}}-->
          <div class="gmap">
            <div class="map-left">
              <yandex-map :settings="yandexMapSettings"
                          :coords="mapCenter"
                          :zoom="10"
                          :show-all-markers="markers.length > 1 ? true : false"
              >
                <ymap-marker
                    v-for="(m, index) in markers"
                    :key="index"
                    :coords="m"
                    marker-id="index"
                    hint-content=""
                />
              </yandex-map>
            </div>

            <div class="map-right">
              <v-text-field
                  placeholder="Введите название ЦЗН"
                  v-model="depsFilter"
                  @input="$forceUpdate()"
                  filled
                  dense
                  rounded
              ></v-text-field>
              <ul>
                <template v-for="dep in departments">
                  <li :key="dep.id + 'sdfer'" v-if="filterDeps(dep.name)" @input="$forceUpdate()">
                    <router-link
                        :to="`/passportczn/info/${activeConfigOfPassportCZN.id}/${dep.id}`">
                      {{ dep.name }}
                    </router-link>
                  </li>
                </template>
              </ul>
            </div>
          </div>

          <router-link :to="'/clientindex?reg='+this.userRegion.code" style="text-decoration: none">
            <div class="clientindex passportinfo">
              <div class="row top-progress">
                <div class="col-lg-4 col">
                  <div class="green">
                    <div>{{ floor2(iccDashboardData.I) }}<span class="text-caption">/50</span>
                    </div>
                    <p> Индекс<br>клиентоцентричности </p></div>
                </div>
                <div class="col-lg-4 col">
                  <div class="blue">
                    <div>{{ floor2(iccDashboardData.Is) }}<span class="text-caption">/50</span></div>
                    <p> Индекс клиентоцентричности с точки зрения соискателей </p></div>
                </div>
                <div class="col-lg-4 col">
                  <div class="yellow">
                    <div>{{ floor2(iccDashboardData.Ir) }}<span class="text-caption">/50</span></div>
                    <p> Индекс клиентоцентричности с точки зрения работодателей </p></div>
                </div>
              </div>
            </div>
          </router-link>

          <div class="list passport">
            <v-expansion-panels v-model="sectionIndex" multiple>
              <v-expansion-panel
                  v-for="(section, index) in config.sections"
                  :key="index"
                  class="list-item-wrap">
                <v-expansion-panel-header>
                  <div class="list-item">
                    <a class="list-item-name">
                      <button class="toggleSwitch">
                        <svg
                            class="closed"
                            fill="none"
                            height="24"
                            viewBox="0 0 24 24"
                            width="24"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                              d="M12 14.5L17 9.5L7 9.5L12 14.5Z"
                              fill="#1551D0"
                          />
                        </svg>
                        <svg
                            class="opened"
                            fill="none"
                            height="24"
                            viewBox="0 0 24 24"
                            width="24"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                              d="M14.5 12L9.5 7L9.5 17L14.5 12Z"
                              fill="#1551D0"
                          />
                        </svg>
                      </button>
                      <span>{{ section.id }}. {{ section.name }}</span>
                    </a>
                    <v-progress-circular
                        :rotate="-90"
                        :size="50"
                        :value="getProgress(section.id)"
                        :width="6"
                        color="#0033A0"
                    >
                      {{ getProgress(section.id) }}
                    </v-progress-circular>
                  </div>
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <table>
                    <thead>
                    <tr>
                      <th colspan="2">Показатели (данные)</th>
                      <th style="width: 300px;">
                        {{ showYear ? showYear : "Данные на текущий момент" }}
                      </th>
                      <th>
                        <svg
                            @click="previousYear()"
                            style="cursor: pointer"
                            fill="none"
                            height="24"
                            viewBox="0 0 24 24"
                            width="24"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                              d="M15.41 16.58L10.83 12L15.41 7.41L14 6L8 12L14 18L15.41 16.58Z"
                              fill="#0033A0"
                          />
                        </svg>
                        <svg
                            @click="nextYear()"
                            style="cursor: pointer"
                            fill="none"
                            height="24"
                            viewBox="0 0 24 24"
                            width="24"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                              d="M8.58997 16.58L13.17 12L8.58997 7.41L9.99997 6L16 12L9.99997 18L8.58997 16.58Z"
                              fill="#0033A0"
                          />
                        </svg>
                      </th>
                    </tr>
                    </thead>
                    <tbody>
                    <template v-for="indicator in section.indicators">
                      <tr :key="userRegion.code + showYear + section.id + '.' + indicator.id">
                        <td>{{ section.id }}.{{ indicator.id }}</td>
                        <td>{{ indicator.name }}</td>
                        <td>
                          {{
                            indicator.dataType === 'OPTIONAL' ? (
                                    getValue(section.id, indicator.id, null) ?
                                        indicator.options.find(it => it.id == getValue(section.id,
                                            indicator.id, null))?.value :
                                        "&mdash;"
                                ) :
                                ((t = getValue(section.id, indicator.id, null)) ? t : "&mdash;")
                          }}
                          <!--                            {{ (t = getValue(section.id, indicator.id, null)) ? t : "&mdash;" }}-->
                          <br>
                          <div class="additional-info">
                            <v-icon v-if="checkAdditionalInfo(section.id, indicator.id, null, 'COMMENT')" class="icon-item icon-comment-outline"></v-icon>
                            <v-icon v-if="getIndicatorEditInfo(section.id, indicator.id, null)?.storedFile!=null" class="icon-item icon-paperclip"></v-icon>
                            <a
                                v-if="getIndicatorEditInfo(section.id, indicator.id, null)?.storedFile != null"
                                href="#"
                                @click.prevent="downloadAttachment(getIndicatorEditInfo(section.id, indicator.id, null))">
                              {{
                                truncateStr(getIndicatorEditInfo(section.id, indicator.id, null)?.storedFile?.name, 15)
                              }}
                            </a>
                          </div>
                        </td>
                        <td>
                          <PassportIndicatorEditDialog
                              :config="getSubconfig(section.id, indicator.id, null)"
                              :config-id="config.id"
                              :section-id="section.id"
                              :indicator-id="indicator.id"
                              :sub-indicator-id="null"
                              :values="filterValues(section.id, indicator.id, null)"
                              :edit-info="getIndicatorEditInfo(section.id, indicator.id, null)"
                              :region="userRegion.code"
                              :editable="editable"
                              :key="dialogKey"
                              @updated="initialize()"
                          />
                        </td>
                      </tr>
                      <tr v-for="subIndicator in indicator.subIndicators"
                          :key="userRegion.code + showYear + section.id + '.' + indicator.id + '.' + subIndicator.id">
                        <td> {{ section.id }}.{{ indicator.id }}.{{ subIndicator.id }}</td>
                        <td>{{ subIndicator.name }}</td>
                        <td>
                          {{
                            subIndicator.dataType === 'OPTIONAL' ? (
                                    getValue(section.id, indicator.id, subIndicator.id) ?
                                        subIndicator.options.find(it => it.id == getValue(section.id,
                                            indicator.id, subIndicator.id))?.value :
                                        "&mdash;"
                                ) :
                                ((t = getValue(section.id, indicator.id, subIndicator.id)) ? t : "&mdash;")
                          }}
                          <!--                            {{ (t = getValue(section.id, indicator.id, subIndicator.id)) ? t : "&mdash;" }}-->
                          <br>
                          <div class="additional-info">
                            <v-icon v-if="checkAdditionalInfo(section.id, indicator.id, subIndicator.id, 'COMMENT')" class="icon-item icon-comment-outline"></v-icon>
                            <v-icon
                                v-if="getIndicatorEditInfo(section.id, indicator.id, subIndicator.id)?.storedFile!=null" class="icon-item icon-paperclip"></v-icon>
                            <a
                                v-if="getIndicatorEditInfo(section.id, indicator.id, subIndicator.id)?.storedFile != null"
                                href="#"
                                @click.prevent="downloadAttachment(getIndicatorEditInfo(section.id, indicator.id, subIndicator.id))">

                              {{
                                truncateStr(getIndicatorEditInfo(section.id, indicator.id, subIndicator.id)?.storedFile?.name, 15)
                              }}
                            </a>
                          </div>
                        </td>
                        <td>
                          <PassportIndicatorEditDialog
                              :config="getSubconfig(section.id, indicator.id, subIndicator.id)"
                              :config-id="config.id"
                              :section-id="section.id"
                              :indicator-id="indicator.id"
                              :sub-indicator-id="subIndicator.id"
                              :values="filterValues(section.id, indicator.id, subIndicator.id)"
                              :edit-info="getIndicatorEditInfo(section.id, indicator.id, subIndicator.id)"
                              :region="userRegion.code"
                              :editable="editable"
                              :key="dialogKey"
                              @updated="initialize()"
                          />
                        </td>
                      </tr>
                    </template>
                    </tbody>
                  </table>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
          </div>
        </div>

        <div
            v-if="errorText != null"
            class="error"
            style="position: fixed; bottom: 0"
        >
          Ошибка: {{ errorText }}
        </div>
      </div>
      <div v-else style="height: 1000px"></div>
    </transition>
  </div>
</template>

<style lang="scss">
@import "../styles/main.scss";
</style>

<script>
import PassportInfoDocsDialog from "./dialogs/PassportInfoDocsDialog";
import PassportInfoDocsUploadDialog from "./dialogs/PassportInfoDocsUploadDialog";
import PassportIndicatorEditDialog from "./dialogs/PassportIndicatorEditDialog";
import {yandexMap, ymapMarker} from 'vue-yandex-maps'
import LoadingComponent from "@/components/elements/LoadingComponent";
import {loadRegionalDashboardData} from "@/modules/IccRepository";
// import api from "@/modules/api";

export default {
  name: "passport",
  props: {},
  components: {
    LoadingComponent,
    PassportIndicatorEditDialog,
    PassportInfoDocsDialog,
    PassportInfoDocsUploadDialog,
    yandexMap,
    ymapMarker
  },
  data() {
    return {
      config: null,
      configId: null,
      dialog: false,
      dialogArchive: false,
      dialogUpload: false,
      sectionIndex: [],
      values: null,
      indicatorsEditInfo: null,
      cznsNumber: null,
      apiLoaded: false,
      editable: false,
      userRegion: {},
      regionList: [],
      departments: [],
      errorText: null,
      showYear: null,
      addresses: [],
      // dialog: false,
      fromCZNMap: false, //чтобы нельзя было выбрать регион и редактировать
      markers: [],
      yandexMapSettings: {
        apiKey: '90a80d24-0d3c-4471-b98d-2acf543bc522', //'2c72f1a3-4eb2-42f7-b8be-5ddb2ef89a81'
        lang: 'ru_RU',
        coordorder: 'latlong',
        enterprise: false,
        version: '2.1',
      },
      mapCenter: [55.738778, 37.629861],
      depsFilter: '',
      activeConfigOfPassportCZN: null,
      dialogKey: 0,
      loadingText: [],
      iccDashboardData: {}
    };
  },
  methods: {
    nextYear() {
      if (this.showYear == null) this.showYear = 2018;
      else if (this.showYear === 2023) this.showYear = null;
      else this.showYear++;
    },

    previousYear() {
      if (this.showYear == null) this.showYear = 2023;
      else if (this.showYear === 2018) this.showYear = null;
      else this.showYear--;
    },

    getValue(sectionId, indicatorId, subIndicatorId) {
      for (let v of this.values) {
        if (
            v.sectionId === sectionId &&
            v.indicatorId === indicatorId &&
            v.subIndicatorId == subIndicatorId //!! тут должно быть именно нестрогое сравнение. почему-то на бэке это стринг
        ) {
          if (v.year === this.showYear) return v.value;
        }
      }
    },

    getIndicatorEditInfo(sectionId, indicatorId, subIndicatorId) {
      if (this.indicatorsEditInfo == null) return null;
      return this.indicatorsEditInfo.find(v => v.sectionId === sectionId &&
          v.indicatorId === indicatorId &&
          v.subIndicatorId == subIndicatorId)
    },

    truncateStr(str, n) {
      if (!str) return ''
      if (str.length > n) {
        return str.substring(0, n - 3) + "..." + str.substring(str.length - 6, str.length)
      }
      return str
    },

    async downloadAttachment(file) {
      await this.$downloadApi(file.storedFile.name, "downloadFile", {fileId: file.storedFile.id})
    },

    getRelevanceDate(sectionId, indicatorId, subIndicatorId) {
      for (let v of this.values) {
        if (
            v.sectionId === sectionId &&
            v.indicatorId === indicatorId &&
            v.subIndicatorId === subIndicatorId
        ) {
          if (v.year === this.showYear) return v.relevanceDate;
        }
      }
    },
    floor2(val) {
      if (!val) val = 0
      return Math.floor(val * 100) / 100
    },

    checkAdditionalInfo(sectionId, indicatorId, subIndicatorId, type) {
      let info = this.getIndicatorEditInfo(sectionId, indicatorId, subIndicatorId);
      if (info != null) {
        if (type === "FILE") {
          return info.storedFile
        } else if (type === "COMMENT") {
          return info.comment
        }
      } else {
        return false
      }
    },

    async loadConfig() {
      let req;
      if (this.configId == null) {
        req = await this.$getApi("/passport/getActiveConfig");
      } else {
        req = await this.$getApi("/passport/getConfig", {id: this.configId});
      }
      if (req.ok) {
        this.config = req.payload;
        this.config.sections = JSON.parse(this.config.sectionsJson);
        this.config.documentCategories = JSON.parse(this.config.documentCategoriesJson);
        this.generateNewConfig()
      } else {
        this.errorText =
            "Ошибка подключения к серверу или обработки запроса: " + req.error;
      }
    },

    generateNewConfig(){
      let sections = JSON.parse(JSON.stringify(this.config.sections))
      for(let section of sections){
        for(let indicator of section.indicators){
          indicator.oldId = indicator.id
          indicator.id = this.uuidv4()
          if(indicator.subIndicators){
            for(let subIndicator of indicator.subIndicators){
              subIndicator.oldId = subIndicator.id
              subIndicator.id = this.uuidv4()
            }
          }
        }
      }
      console.log(JSON.stringify(sections))
    },

    uuidv4() {
      return "10000000-1000-4000-8000-100000000000".replace(/[018]/g, c =>
          (+c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> +c / 4).toString(16)
      );
    },

    filterValues(sectionId, indicatorId, subIndicatorId) {
      let resv = [];
      for (let v of this.values) {
        if (
            v.sectionId === sectionId &&
            v.indicatorId === indicatorId &&
            v.subIndicatorId == subIndicatorId
        )
          resv.push({
            region: v.region,
            configId: v.configId,
            sectionId: v.sectionId,
            indicatorId: v.indicatorId,
            subIndicatorId: v.subIndicatorId,
            year: v.year,
            value: v.value,
          });
      }
      return resv;
    },

    getSubconfig(sectionId, indicatorId, subIndicatorId) {
      for (let section of this.config.sections) {
        if (section.id === sectionId) {
          for (let indicator of section.indicators) {
            if (indicator.id === indicatorId) {
              if (subIndicatorId == null) {
                return indicator;
              }
              for (let subIndicator of indicator.subIndicators) {
                if (subIndicator.id === subIndicatorId) return subIndicator;
              }
            }
          }
        }
      }
    },

    getProgress(sectionId) {
      return Math.round(
          (this.getSectionValuesPresent(sectionId) /
              this.getSectionValuesCount(sectionId)) *
          100
      );
    },

    getSectionValuesCount(sectionId) {
      let count = 0;
      for (let section of this.config.sections) {
        if (section.id === sectionId) {
          count += section.indicators.length;
          for (let indicator of section.indicators) {
            if (indicator.subIndicators != null)
              count += indicator.subIndicators.length;
          }
        }
      }
      return count;
    },

    getSectionValuesPresent(sectionId) {
      let count = 0;
      for (let v of this.values) {
        if (v.sectionId === sectionId && v.year == null) count++;
      }
      return count;
    },

    // getRegion() {
    //   return this.$getUserByName(this.$behalfUser).region;
    // },

    async loadValues() {
      let req = await this.$getApi("/passport/getValues", {
        region: this.userRegion.code,
        configId: this.config.id,
      });
      if (req.ok) {
        this.values = req.payload;
      } else {
        this.errorText =
            "Ошибка подключения к серверу или обработки запроса: " + req.error;
      }
    },

    async loadIndicatorsEditInfo() {
      let req = await this.$getApi("/passport/getIndicatorsEditInfo", {
        region: this.userRegion.code,
        configId: this.config.id,
      });
      if (req.ok) {
        this.indicatorsEditInfo = req.payload;
      } else {
        this.errorText =
            "Ошибка подключения к серверу или обработки запроса: " + req.error;
      }
    },

    getCznsNumber() {
      if (this.$user.role === 'ROLE_CZN_TERRITORIAL' || this.$user.role === 'ROLE_CZN_MANAGER' || this.$user.role === 'ROLE_REGIONAL_AGENCY') {
        this.cznsNumber = this.findCznsCount();
      } else {
        this.cznsNumber = this.userRegion.departments.length;
      }
    },

    findCznsCount() {
      let region = this.regionList.filter((region) => {
        return region.code === this.userRegion.code
      });
      return region[0].departments.length;
    },

    getSection(sectionId) {
      for (let s of this.config.sections) {
        if (s.id === sectionId) return s;
      }
    },

    getIndicator(section, indicatorId) {
      for (let i of section.indicators) {
        if (i.id === indicatorId) return i;
      }
    },

    getSubIndicator(indicator, subIndicatorId) {
      for (let si of indicator.subIndicators) {
        if (si.id === subIndicatorId) {
          return si;
        }
      }
    },

    forceRerender() {
      this.dialogKey += 1;
    },

    setMapMarkers() {
      for (let department of this.userRegion.departments) {
        if (department.coords) {
          let coords_arr = department.coords.split(',')
          this.markers.push([coords_arr[1], coords_arr[0]])
        }
      }
    },

    async initialize() {
      await this.loadConfig();
      await this.loadValues();
      await this.loadIndicatorsEditInfo();
      this.setMapMarkers();
      this.getCznsNumber();
      this.iccDashboardData = await loadRegionalDashboardData(3, this.userRegion.code)
      this.forceRerender();
    },

    exportXls() {
      this.export("xlsx");
    },

    exportPdf() {
      this.export("pdf");
    },

    async export(format) {
      this.showLoaderDialog = true;
      let query = {
        headerCells: [
          {
            caption: "Раздел",
            width: 10000,
          },
          {
            caption: "Показатель",
            width: 10000,
          },
          {
            caption: "Год",
            width: 4000,
          },
          {
            caption: "Значение",
            width: 15000,
          },
        ],
        rows: [],
      };

      query.rows.push([
        {cellDataType: "STRING", value: "Общая информация о службе занятости субъекта РФ"},
        {cellDataType: "STRING", value: "Субъект РФ"},
        {cellDataType: "INTEGER", value: ""},
        {cellDataType: "STRING", value: this.userRegion.name},
      ]);

      for (let v of this.values) {
        let section = this.getSection(v.sectionId);
        let indicator = this.getIndicator(section, v.indicatorId);
        if (v.subIndicatorId != null) {
          indicator = this.getSubIndicator(indicator, v.subIndicatorId);
        }
        query.rows.push([
          {cellDataType: "STRING", value: section.name},
          {cellDataType: "STRING", value: indicator.name},
          {cellDataType: "INTEGER", value: v.year},
          {cellDataType: "STRING", value: v.value},
        ]);
      }

      await this.$downloadApi("ИС_Мониторинга_СЗН_Паспорт." + format.toLowerCase(), "/export/" + format, query, true);
      this.showLoaderDialog = false;
    },

    getRegionByCode(regionCode) {
      for (let region of this.regionList) {
        if (region.code === regionCode) {
          return region
        }
      }
    },

    getDepartments(regionCode) {
      for (let region of this.regionList) {
        if (region.code === regionCode) {
          this.departments = region.departments.filter(e=>['territorial_czn', 'manager_czn'].includes(e.type))
          console.log('departments', this.departments)
        }
      }
    },

    filterDeps(name) {
      return name.toUpperCase().includes(this.depsFilter.toUpperCase())
    },

    async loadActiveConfigOfPassportCZN() {
      let req = await this.$getApi("/passportCzn/getActiveConfig");
      if (req.ok) {
        this.activeConfigOfPassportCZN = req.payload;
      } else {
        this.errorText =
            "Ошибка подключения к серверу или обработки запроса: " + req.error;
      }
    },
  },

  async beforeMount() {
    this.configId = this.$route.params.configId;
    this.regionList = this.$cznDepartmentsByRegionList;
    if (this.$user.role === 'ROLE_CZN_TERRITORIAL' || this.$user.role === 'ROLE_CZN_MANAGER' || this.$user.role === 'ROLE_REGIONAL_AGENCY') {
      this.userRegion = this.regionList.find(e => e.code === this.$user.region.code);
    } else { //для админов и ВНИИ Труда
      this.userRegion = this.regionList[0];
    }
    let regionCode = this.$route.params.region,
        index = this.$route.params.sectionIndex;
    if (regionCode != null) { //при прямом переходе с карты СЗН
      this.fromCZNMap = true;
      this.userRegion = this.getRegionByCode(regionCode);
      if (index) {
        this.sectionIndex = index.split("-").map(item => Number(item));
      }
    }
    this.loadingText.push('Загружается карточка паспорта региона')
    await this.loadActiveConfigOfPassportCZN();
    this.loadingText.splice(this.loadingText.length - 1, 1)
    this.loadingText.push('Загружена карточка паспорта региона')

    this.getDepartments(this.userRegion.code);

    this.loadingText.push('Загружаются данные паспорта региона')
    await this.initialize();
    this.loadingText.splice(this.loadingText.length - 1, 1)
    this.loadingText.push('Загружены данные паспорта региона')

    this.editable = !this.fromCZNMap
        && (this.$user.subroles.includes('REGION_PASSPORT_EDITOR') || this.$user.role === 'ROLE_ADMIN' || this.$user.role === 'ROLE_VNII')
        && this.config.active;
    this.apiLoaded = true;
  },

  // async mounted() {
  //   this.$nextTick(async function () {
  //     // await this.loadAddressesPromise;
  //     if (this.departments.length === 0 || this.addresses.length === 0) {
  //       console.log('region', this.userRegion.name)
  //       this.mapCenter = await this.getCoords(this.userRegion.name)
  //     } else {
  //       for (let address of this.addresses) {
  //         this.markers.push(await this.getCoords(address))
  //       }
  //       this.mapCenter = this.markers[0]
  //     }
  //   })
  // },
};
</script>


<style lang="scss">
@import "../styles/main.scss";
</style>

<style lang="scss" scoped>
.subheader {
  font-size: 17px;
  color: #1C1C1F;
  font-weight: 600;
}

.additional-info {
  div {
    display: inline-block;
    min-width: 65px;
    font-size: 13px;
    font-weight: 500;
    color: #777779;
  }

  .v-icon {
    display: inline-block;
    font-size: 14px;
    color: #777779;
  }

  .v-icon:nth-child(2) {
    margin-left: 5px;
  }
}
</style>
