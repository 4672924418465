<template>
  <v-dialog
      v-model="dialog"
      max-width="600px"
      persistent
  >
    <template v-slot:activator="{ on, attrs }">
      <a v-bind="attrs"
         v-on="on"
         href="#"
         @click.prevent
      >
        <div class="edit">
          <svg fill="none" height="24" viewBox="0 0 24 24" width="24"
               xmlns="http://www.w3.org/2000/svg">
            <path
                d="M18.13 12L19.39 10.74C19.83 10.3 20.39 10.06 21 10V9L15 3H5C3.89 3 3 3.89 3 5V19C3 20.1 3.89 21 5 21H11V19.13L11.13 19H5V5H12V12H18.13ZM14 4.5L19.5 10H14V4.5ZM19.13 13.83L21.17 15.87L15.04 22H13V19.96L19.13 13.83ZM22.85 14.19L21.87 15.17L19.83 13.13L20.81 12.15C21 11.95 21.33 11.95 21.53 12.15L22.85 13.47C23.05 13.67 23.05 14 22.85 14.19Z"
                fill="#0033A0"/>
          </svg>
        </div>
      </a>
    </template>
    <v-card>
      <v-card-title>
        <span class="text-h5">{{config.name}}</span>
        <a class="close" @click="dialog=false">
          <svg fill="none" height="24" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M19 6.41L17.59 5L12 10.59L6.41 5L5 6.41L10.59 12L5 17.59L6.41 19L12 13.41L17.59 19L19 17.59L13.41 12L19 6.41Z"
                fill="#0033A0"/>
          </svg>
        </a>
      </v-card-title>
      <v-card-text>
        <v-container>

          <v-row>

            <v-col cols="12">
              <h4>Данные на текущий момент</h4>
            </v-col>

            <v-col cols="12">
              <PassportIndicatorEditBox
                :value="getValue(null)"
                :year="null"
                :type="config.dataType"
                :options="config.options"
                @change="setValue"
              ></PassportIndicatorEditBox>
            </v-col>

            <template v-if="config.yearly">
              <v-col cols="12">
                <h4>Данные по годам</h4>
              </v-col>

              <v-col cols="12">
                <PassportIndicatorEditBox
                    :value="getValue(2023)"
                    :year="2023"
                    :type="config.dataType"
                    :options="config.options"
                    @change="setValue"
                ></PassportIndicatorEditBox>
              </v-col>

              <v-col cols="12">
                <PassportIndicatorEditBox
                    :value="getValue(2022)"
                    :year="2022"
                    :type="config.dataType"
                    :options="config.options"
                    @change="setValue"
                ></PassportIndicatorEditBox>
              </v-col>

              <v-col cols="12">
                <PassportIndicatorEditBox
                    :value="getValue(2021)"
                    :year="2021"
                    :type="config.dataType"
                    :options="config.options"
                    @change="setValue"
                ></PassportIndicatorEditBox>
              </v-col>

              <v-col cols="12">
                <PassportIndicatorEditBox
                    :value="getValue(2020)"
                    :year="2020"
                    :type="config.dataType"
                    :options="config.options"
                    @change="setValue"
                ></PassportIndicatorEditBox>
              </v-col>

              <v-col cols="12">
                <PassportIndicatorEditBox
                    :value="getValue(2019)"
                    :year="2019"
                    :type="config.dataType"
                    :options="config.options"
                    @change="setValue"
                ></PassportIndicatorEditBox>
              </v-col>

              <v-col cols="12">
                <PassportIndicatorEditBox
                    :value="getValue(2018)"
                    :year="2018"
                    :type="config.dataType"
                    :options="config.options"
                    @change="setValue"
                ></PassportIndicatorEditBox>
              </v-col>

<!--              <v-col cols="12">-->
<!--                <h4>Архивные данные</h4>-->
<!--              </v-col>-->

<!--              <v-col-->
<!--                  cols="12"-->
<!--                  sm="6"-->
<!--              >-->
<!--                <v-select-->
<!--                    :items="['2017', '2016', '2105', '2014']"-->
<!--                    label="Выберите год"-->
<!--                ></v-select>-->
<!--              </v-col>-->
<!--              <v-col-->
<!--                  cols="12"-->
<!--                  sm="6"-->
<!--              >-->
<!--                <v-text-field-->
<!--                    label="Значение"-->
<!--                ></v-text-field>-->
<!--              </v-col>-->
            </template>

            <v-col cols="12">
              <h4>Комментарий</h4>
            </v-col>

            <v-col cols="12">
              <v-textarea v-model="query.comment" placeholder="Напишите комментарий (необязательно)"></v-textarea>
            </v-col>

            <v-col cols="12">
              <h4>Вложения</h4>
            </v-col>

            <v-col v-if="!editInfo || editInfo.storedFile == null" cols="12">
              <v-file-input v-model="attachedFile"></v-file-input>
            </v-col>
            <v-col v-else cols="12">
              <a href="#" @click.prevent="downloadAttachment">{{editInfo.storedFile.name}}</a>
              <a href="#" @click.prevent="deleteAttachment"><v-icon class="icon-item icon-trash-can"></v-icon></a>
            </v-col>

            <v-col cols="12">
              <p>Последние изменения: {{editInfo ? editInfo.edited : '&mdash;'}}</p>
            </v-col>

          </v-row>
        </v-container>


      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
            class="btn btn-outline"
            text
            @click="dialog=false"
        >
          Закрыть
        </v-btn>
        <v-btn
            class="btn btn-outline"
            text
            :disabled="submitButtonDisabled || !editable || !isValidValuesList()"
            @click="submit()"
        >
          Сохранить изменения
        </v-btn>
      </v-card-actions>
      <div v-if="errorText!=null" class="error" style="position: fixed;bottom: 0">
        Ошибка: {{ errorText }}
      </div>
    </v-card>
  </v-dialog>
</template>

<script>
import PassportIndicatorEditBox from "../elements/PassportIndicatorEditBox";

export default {
  name: 'PassportIndicatorEditDialog',
  components: {PassportIndicatorEditBox},
  props: ['config', 'configId', 'region', 'sectionId', 'indicatorId', 'subIndicatorId', 'values', 'editInfo', 'editable'],
  data: () => ({
    dialog: false,
    submitButtonDisabled: false,
    query: {},
    errorText: null,
    attachedFile: null
  }),
  methods: {
    getValue(year){
      for(let v of this.query.passportValues) {
        if (v.year === year)
          return v.value
      }
      return null
    },

    deleteValue(year){
      for(let i = 0; i < this.query.passportValues.length; i++){
        if(this.query.passportValues[i].year === year){
          this.query.passportValues.splice(i, 1)
        }
      }
    },

    setValue(year, value){
      this.deleteValue(year)
      if(value != null && value !== ''){
        this.query.passportValues.push({
          year: year,
          region: this.region,
          configId: this.configId,
          sectionId: this.sectionId,
          indicatorId: this.indicatorId,
          subIndicatorId: this.subIndicatorId,
          value: value
        })
      }
    },

    async downloadAttachment(){
      await this.$downloadApi(this.editInfo.storedFile.name,"downloadFile", {fileId: this.editInfo.storedFile.id})
    },

    deleteAttachment(){
      this.attachedFile = null
      if(this.editInfo){
        this.editInfo.storedFile = null
      }
      this.fileDeleted = true
    },

    async submit() {
      if (!this.isValidValuesList()) {
        return
      }

      this.submitButtonDisabled = true
      // console.log('this.query', this.query)
      let req = await this.$postApi("/passport/saveValues", this.query)
      if (req.ok) {
        this.dialog = false
      } else {
        this.errorText = "Ошибка подключения к серверу или обработки запроса: " + req.error
        this.submitButtonDisabled = false
        return
      }

      // console.log('this.attachedFile', this.attachedFile)
      if(this.attachedFile != null){
        let formData = new FormData();
        formData.append("region", this.region)
        formData.append("configId", this.configId)
        formData.append("sectionId", this.sectionId);
        formData.append("indicatorId", this.indicatorId);
        if (this.subIndicatorId !== null)
          formData.append("subIndicatorId", this.subIndicatorId);
        formData.append("multipartFile", this.attachedFile);
        // console.log('formData', formData)
        let req2 = await this.$postFormDataApi("/passport/addIndicatorEditInfoFile", formData)
        if (req2.ok){
          console.log('req2 ok')
        } else {
          this.errorText = "Ошибка подключения к серверу или обработки запроса: " + req2.error
          return
        }
      } else if(this.fileDeleted){
        // удаляем файл
        let formData = new FormData();
        formData.append("region", this.region)
        formData.append("configId", this.configId)
        formData.append("sectionId", this.sectionId);
        formData.append("indicatorId", this.indicatorId);
        if (this.subIndicatorId !== null)
          formData.append("subIndicatorId", this.subIndicatorId);
        let req2 = await this.$postFormDataApi("/passport/addIndicatorEditInfoFile", formData)
        if (req2.ok){
          console.log('req2 ok')
        } else {
          this.errorText = "Ошибка подключения к серверу или обработки запроса: " + req2.error
          return
        }
      }

      this.submitButtonDisabled = false
      this.$emit('updated')
    },

    isValidValuesList(){
      for(let v of this.query.passportValues){
        if(this.isValidValue(v.value) !== true)
          return false
      }
      return true
    },

    isValidValue(value) {
      if(value == null || value == "")
        return true
      if (this.config.dataType === 'DECIMAL') {
        if (!this.isNumeric(value)) {
          return "Некорректное число"
        }
      } else if (this.config.dataType === 'INTEGER') {
        if (!this.isNormalInteger(value)) {
          return "Некорректное целое число"
        }
      } else if (this.config.dataType === 'TEXT') {
        if (value.length > 4000) {
          return "Длина текста не может превышать 4000 символов"
        }
      }
      return true
    },
    isNormalInteger(str) {
      var n = Math.floor(Number(str));
      return n !== Infinity && String(n) === str && n >= 0;
    },

    isNumeric(str) {
      if (typeof str != "string") return false // we only process strings!
      let replacedCommaStr = Number(str.replace(/,/, '.')); //замена запятой на точку для проверки
      return !isNaN(replacedCommaStr) && // use type coercion to parse the _entirety_ of the string (`parseFloat` alone does not do this)...
          !isNaN(parseFloat(replacedCommaStr)) // ...and ensure strings of whitespace fail
    },

    // isNumeric(str) {
    //   if (typeof str != "string") return false // we only process strings!
    //   return !isNaN(str) && // use type coercion to parse the _entirety_ of the string (`parseFloat` alone does not do this)...
    //       !isNaN(parseFloat(str)) // ...and ensure strings of whitespace fail
    // }
  },
  beforeMount() {
    this.query={
      region: this.region,
      configId: this.configId,
      sectionId: this.sectionId,
      indicatorId: this.indicatorId,
      subIndicatorId: this.subIndicatorId,
      passportValues: this.values,
      comment: this.editInfo ? this.editInfo.comment : null
    }
  }
}
</script>
